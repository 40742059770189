<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-form class="multi-col-validation">
        <v-card-title>Nastavte si přesměrování</v-card-title>
        <v-card-text class="pt-5">
          <v-row>

            <v-col
              cols="4"
              md="4"
            >
              <!-- DATE FROM -->
              <v-menu
                v-model="menu_date_from"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedDateFormattedDateFrom"
                    label="Zapnout presměrováni od data"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="optionsLocal.date_from = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="optionsLocal.date_from"
                  :min="this.$moment(new Date()).format('YYYY-MM-DD')"
                  locale="cs"
                  @change="menu_date_from = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- TIME FROM -->
            <v-col>
              <v-menu
                ref="menu_t_from"
                v-model="menu_time_from"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="optionsLocal.time_from"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedDateFormattedTimeFrom"
                    label="a času od"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu_time_from"
                  v-model="optionsLocal.time_from"
                  :min="min_time_from"
                  full-width
                  format="24hr"
                  :scrollable="false"
                  :allowed-minutes="[0, 15, 30, 45]"
                  no-double-click-blur
                  @click:minute="$refs.menu_t_from.save(optionsLocal.time_from)"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col
              cols="4"
              md="4"
            >
              <!-- DATE TO -->
              <v-menu
                v-model="menu_date_to"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedDateFormattedDateTo"
                    label="presměrováni do data"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="optionsLocal.date_to = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="optionsLocal.date_to"
                  :min="date_from"
                  locale="cs"
                  @change="menu_date_to = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- TIME TO -->
            <v-col>
              <v-menu
                ref="menu_t_to"
                v-model="menu_time_to"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="optionsLocal.time_to"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedDateFormattedTimeTo"
                    label="a času do"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu_time_to"
                  v-model="optionsLocal.time_to"
                  full-width
                  format="24hr"
                  :scrollable="false"
                  :allowed-minutes="[0, 15, 30, 45]"
                  :min="time_from"
                  no-double-click-blur
                  @click:minute="$refs.menu_t_to.save(optionsLocal.time_to)"
                  @change="btn_save_disabled = false"
                ></v-time-picker>
              </v-menu>
            </v-col>

          </v-row>

          <v-row>
            <!--  PRAZDNY STLPEC -->
            <v-col
              cols="6"
              md="6"
            >
            </v-col>

            <!-- FORWARD TO ADDRESS -->
            <v-col
              cols="6"
              md="6"
            >
              <v-form
                ref="form"
                v-model="form_valid"
                lazy-validation
              >
                  <v-text-field
                    v-model="optionsLocal.forward_to"
                    clearable
                    outlined
                    dense
                    :rules="forwardToRules"
                    label="email. adresa pro přesměrování"
                    @blur="handleForwardTo"
                    @input="optionsLocal.forward_to && optionsLocal.forward_to.length > 0 ? btn_save_disabled = false : btn_save_disabled = true"
                  >
                    <div slot="append">
                      <div style="padding-top:5px">@indexnoslus.cz</div>
                    </div>
                  </v-text-field>
              </v-form>
            </v-col>

          </v-row>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <div v-if="info.forward_to && info.forward_to.length > 0">
          <v-card-title>Přesměrování</v-card-title>

          <v-card-text>
              <v-chip v-if="info.date_from">{{ info.date_from | moment('DD.MM.YYYY') }} {{ info.time_from }}</v-chip>
              <span style="margin-right:10px"></span>
              <v-chip v-if="info.date_to">{{ info.date_to | moment('DD.MM.YYYY') }} {{ info.time_to }}</v-chip>
              <span style="margin-right:10px"></span>
              <v-chip v-if="info.forward_to && info.forward_to.length > 0">{{ info.forward_to }}@indexnoslus.cz</v-chip>
              <span style="margin-right:10px"></span>
              <v-btn
                :loading="loading_is_forward"
                :disabled="loading_is_forward"
                color="primary"
                title="Zrušit nastavení přesměrování"
                icon
                @click="dialog_remove_forward = true"
              >
                <v-icon size="22">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
          </v-card-text>
        </div>

        <!-- TLACITKA -->
        <v-card-text>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            :disabled="btn_save_disabled"
            @click="setForward()"
          >
            Uložit změny
          </v-btn>
          <v-btn
            outlined
            class="mt-3"
            color="secondary"
            type="reset"
            @click.prevent="doCancel"
          >
            Zrušit
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
    <!-- DIALOG - odstran presmerovanie -->
    <v-dialog
      persistent
      v-model="dialog_remove_forward"
      max-width="430"
    >
      <v-card>
        <v-card-title class="text-h5">
          Přesměrování emailového účtu
        </v-card-title>

        <v-card-text>
          Opravdu chcete odstranit přesměrování emailového účtu?
          <!-- <div v-show="show_tip" style="font-size:10px;margin-top:10px;width:100%;text-align:right;">Čekám na odpoveď z majlového servru <b>ZIMBRA</b>...</div> -->
          <v-chip
            v-if="show_tip"
            class="ma-2"
            color="#D32F2F"
            label
            outlined
          >
            Čekám na odpoveď z majlového servru  <b style="margin-left: 10px;">ZIMBRA</b>...
          </v-chip>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            :disabled="loading_btn_remove"
            @click="dialog_remove_forward = false"
          >
            Zrušit
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="removeForwardTo()"
            :loading="loading_btn_remove"
          >
            Odstranit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint no-unreachable: "off" */
/* eslint object-shorthand: "off" */

import axios from 'axios'
import { ref } from '@vue/composition-api'
import { add, format, parseISO } from 'date-fns'
import { cs } from 'date-fns/locale'
import { mdiDeleteOutline } from '@mdi/js'
import response from '@/mixins/response'

export default {
  mixins: [response],
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
    info: {},
  },
  setup(props) {
    const optionsLocal = ref(JSON.parse(JSON.stringify(props.informationData)))

    const resetForm = () => {
      optionsLocal.value = JSON.parse(JSON.stringify(props.informationData))
    }

    return {
      optionsLocal,
      resetForm,

      menu_date_from: false,
      menu_time_from: false,
      menu_date_to: false,
      menu_time_to: false,
      date_from: new Date().toISOString(),
      time_from: '00:00',
      min_time_from: '00:00',

      loading_is_forward: false,
      loading_btn_remove: false,

      form_valid: false,
      forwardToRules: [
        v => !!v || 'Adresa, na ktorú sa má email presmerovať musí byť zadaná!',
      ],

      icons: {
        mdiDeleteOutline,
      },

      dialog_remove_forward: false,
      btn_save_disabled: true,
      show_tip: false,
    }
  },
  watch: {
    'optionsLocal.date_from': function (new_val) {
      this.date_from = new Date(new_val).toISOString()
      if (this.$moment(this.optionsLocal.date_to).isBefore(this.$moment(new_val))) {
        this.optionsLocal.date_to = this.$moment(new Date(new_val)).add(7, 'days').format('YYYY-MM-DD')
      }

      // ---
      this.checkTime()
    },
    'optionsLocal.date_to': function () {
      this.checkTime()
    },
    'optionsLocal.time_from': function () {
      this.checkTime()
    },
  },
  computed: {
    computedDateFormattedDateFrom() {
      return this.optionsLocal.date_from ? format(parseISO(this.optionsLocal.date_from), 'EEEE, do MMMM yyyy', { locale: cs }) : format(new Date(), 'EEEE, do MMMM yyyy', { locale: cs })
    },
    computedDateFormattedDateTo() {
      return this.optionsLocal.date_to ? format(parseISO(this.optionsLocal.date_to), 'EEEE, do MMMM yyyy', { locale: cs }) : format(add(new Date().setHours(23, 59, 0, 0), { days: 7 }), 'EEEE, do MMMM yyyy', { locale: cs })
    },
    computedDateFormattedTimeFrom() {
      let now = new Date()
      now.setHours(17, 0, 0, 0)

      return this.optionsLocal.time_from ? this.optionsLocal.time_from : format(now, 'HH:mm')
    },
    computedDateFormattedTimeTo() {
      let now = new Date()
      now.setHours(17, 0, 0, 0)

      return this.optionsLocal.time_to ? this.optionsLocal.time_to : format(now, 'HH:mm')
    },
  },
  created() {
    this.date_from = this.$moment(new Date()).format('YYYY-MM-DD')
    this.checkTime()
  },
  methods: {
    doCancel() {
      this.$router.push('/dashboard')
    },
    setForward(p_id) {
      p_id = p_id || this.$store.getters.getProfile._id
      if (!this.optionsLocal) {
        return
      }

      if (this.$refs.form.validate() === true) {
        let data = {
          email_forward_to: _.cloneDeep(this.optionsLocal),
        }
        data.email_forward_to.forward_to += '@indexnoslus.cz'
        data.email_forward_to.is_active = true
        let time_from = data.email_forward_to.time_from.split(':')
        data.email_forward_to.date_from = this.$moment(data.email_forward_to.date_from).set({
          hour: time_from[0],
          minute: time_from[1],
        })
        delete data.email_forward_to.time_from
        let time_to = data.email_forward_to.time_to.split(':')
        data.email_forward_to.date_to = this.$moment(data.email_forward_to.date_to).set({
          hour: time_to[0],
          minute: time_to[1],
        })
        delete data.email_forward_to.time_to
        const config = {
          url: `${process.env.VUE_APP_BASE_API}/api/v2/user/updateAccount/${p_id}`,
          data: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'put',
        }

        axios(config)
          .then(resp => {
            let snackbar = {
              snack_text: 'Presmerovanie bylo uložené.',
              snack_color: 'primary',
            }
            this.$emit('snackbar', snackbar)
            this.$emit('finish', _.cloneDeep(this.optionsLocal))
            this.btn_save_disabled = true
          })
          .catch(err => {
            console.error(err)
            let snackbar = {
              snack_text: this.responseError(err),
              snack_color: 'red',
            }
            this.$emit('snackbar', snackbar)
          })
      }
    },
    removeForwardTo(p_id) {
      this.loading_btn_remove = true
      this.show_tip = false
      p_id = p_id || this.$store.getters.getProfile._id
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/user/removeForwardTo/${p_id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      setTimeout(() => { // iba info
        this.show_tip = true
      }, 2500)
      axios(config)
        .then(resp => {
          let snackbar = {
            snack_text: 'Presmerovanie bylo zrušené.',
            snack_color: 'primary',
          }
          this.show_tip = false
          this.loading_btn_remove = false
          this.$emit('snackbar', snackbar)
          this.$emit('finish', {})
          this.dialog_remove_forward = false
        })
        .catch(err => {
          this.loading_btn_remove = false
          this.show_tip = false
          console.error(err)
          let snackbar = {
            snack_text: this.responseError(err),
            snack_color: 'red',
          }
          this.$emit('snackbar', snackbar)
          this.dialog_remove_forward = false
        })
    },
    handleForwardTo() {
      if (!this.optionsLocal.forward_to) return

      if (this.optionsLocal.forward_to.indexOf('@') > -1) {
        this.optionsLocal.forward_to = this.optionsLocal.forward_to.split('@')[0]
      }
    },
    checkTime() {
      // time_to
      if (this.$moment(this.optionsLocal.date_to).isSame(this.$moment(this.optionsLocal.date_from), 'day')) {
        let arr_from = this.optionsLocal.time_from.split(':')
        let hour_from = Number(arr_from[0])
        this.time_from = `${hour_from.toString()}:00`

        let arr_to = this.optionsLocal.time_to.split(':')
        let hour_to = Number(arr_to[0])
        if (hour_from > hour_to) {
          this.optionsLocal.time_to = `${(hour_from + 1).toString()}:00`
        }
      } else {
        this.time_from = '00:00'
      }

      // time_from
      if (this.$moment(new Date()).isSame(this.$moment(this.optionsLocal.date_from), 'day')) {
        this.min_time_from = this.$moment(new Date()).add(1, 'hour').format('HH:mm')
      } else {
        this.min_time_from = '00:00'
      }

      this.btn_save_disabled = false
    },
  },
}
</script>
