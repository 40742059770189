<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-form class="multi-col-validation">
      <v-card-text class="pt-5">
        <v-row>
          <!-- NOTES(bio) -->
          <v-col cols="12">
            <v-textarea
              v-model="optionsLocal.notes"
              outlined
              rows="3"
              label="Něco o sebe"
            ></v-textarea>
          </v-col>

          <!-- TITUL -->
          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              v-model="optionsLocal.title"
              outlined
              dense
              label="Titul"
            ></v-text-field>
          </v-col>

          <!-- PRACOVNA POZICIA -->
          <v-col
            cols="12"
            md="6"
          >
            <!-- <v-select
              v-model="optionsLocal.country"
              outlined
              dense
              label="Krajina"
              :items="['CZE','SVK','POL','HUN','GER','AUS','ITA','FRA']"
            ></v-select> -->
            <v-text-field
              v-model="optionsLocal.jobTitle"
              outlined
              dense
              label="Pracovní pozice"
            ></v-text-field>
          </v-col>

          <!-- DATUM NARODENIA -->
          <v-col
            cols="12"
            md="6"
          >
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedMomentjs"
                  label="Dátum narodenia"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="optionsLocal.birthday = null"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="optionsLocal.birthday"
                locale="cs-CS"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- WEBOVA STRANKA -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="optionsLocal.website"
              outlined
              dense
              label="Vaše webová stránka"
            ></v-text-field>
          </v-col>

          <!-- TELEFON -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="optionsLocal.phone"
              outlined
              dense
              label="Telefon"
            ></v-text-field>
          </v-col>

          <!-- KLAPKA -->
          <v-col
            cols="12"
            md="6"
          >
            <!-- <v-select
              v-model="optionsLocal.languages"
              outlined
              dense
              multiple
              chips
              small-chips
              label="Jazyky"
              :items="['English','Slovak','Hungarian','Polish','French','German']"
            ></v-select> -->
            <v-text-field
              v-model="optionsLocal.phoneline"
              outlined
              dense
              label="Klapka"
            ></v-text-field>
          </v-col>

          <!-- ADRESA -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="optionsLocal.address"
              outlined
              dense
              label="Adresa pobočky"
            ></v-text-field>
          </v-col>

          <!-- MESTO -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="optionsLocal.city"
              outlined
              dense
              label="Pobočka"
            ></v-text-field>
          </v-col>

          <!-- POHLAVIE -->
          <v-col
            cols="12"
            md="6"
          >
            <p class="text--primary mt-n3 mb-2">
              Pohlaví
            </p>
            <v-radio-group
              v-model="optionsLocal.gender"
              row
              class="mt-0"
              hide-details
            >
              <v-radio
                value="male"
                label="Muž"
              >
              </v-radio>
              <v-radio
                value="female"
                label="Žena"
              >
              </v-radio>
              <v-radio
                value="other"
                label="Neuvádím"
              >
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-btn
          color="primary"
          class="me-3 mt-3"
          @click="updateTelSeznam()"
        >
          Uložit změny
        </v-btn>
        <v-btn
          outlined
          class="mt-3"
          color="secondary"
          type="reset"
          @click.prevent="doCancel"
        >
          Zrušit
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */

import axios from 'axios'
import { ref } from '@vue/composition-api'

export default {
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const optionsLocal = ref(JSON.parse(JSON.stringify(props.informationData)))

    const resetForm = () => {
      optionsLocal.value = JSON.parse(JSON.stringify(props.informationData))
    }

    return {
      optionsLocal,
      resetForm,
      menu2: false,
    }
  },
  computed: {
    computedDateFormattedMomentjs() {
      let date = this.optionsLocal.birthday ? this.$moment(this.optionsLocal.birthday).format('DD.MM.YYYY', 'sk') : ''

      return date
    },
  },
  methods: {
    doCancel() {
      this.$router.push('/dashboard')
    },
    updateTelSeznam() {
      let p_id = this.optionsLocal.telseznam_id || undefined
      if (!p_id) {
        return
      }

      // ---

      let data = _.cloneDeep(this.optionsLocal)
      if (data.birthday) {
        data.birthday = this.$moment(data.birthday, 'YYYY-MM-DD').toDate()
      }
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/telseznam/${p_id}`,
        data: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'put',
      }
      axios(config)
        .then(resp => {
          // console.log('telseznam =>', resp.data.db_data)
          let snackbar = {
            snack_text: 'Data uživatele byla uložena.',
            snack_color: 'primary',
          }
          this.$emit('snackbar', snackbar)
        })
        .catch(err => {
          console.error(err)
          let snackbar = {
            snack_text: this.responseError(err),
            snack_color: 'red',
          }
          this.$emit('snackbar', snackbar)
        })
    },
  },
}
</script>
