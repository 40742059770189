<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>

          <v-col
            cols="12"
            sm="12"
          >
          <v-select
              v-model="accountDataLocale.hostname"
              :items="accountDataLocale.select_data"
              :menu-props="{ maxHeight: '400' }"
              label="Select"
              multiple
              hint="Označte povolené terminály"
              persistent-hint
              :disabled="!$store.getters.getProfile.is_admin"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              :disabled="!$store.getters.getProfile.is_admin"
              @click="saveTerminals()"
            >
              Uložit změny
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="doCancel"
            >
              Zrušit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */

import axios from 'axios'
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import response from '@/mixins/response'

export default {
  mixins: [response],
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const status = ['Aktivní', 'Neaktivní']

    const accountDataLocale = ref(props.accountData) // ref(JSON.parse(JSON.stringify(props.accountData)))

    const resetForm = () => {
      accountDataLocale.value = JSON.parse(JSON.stringify(props.accountData))
    }

    return {
      status,
      accountDataLocale,
      resetForm,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
  watch: {

  },
  methods: {
    isWoman(p_val) {
      /* eslint newline-before-return: "off" */

      if (p_val) {
        if (p_val.indexOf('ová') > -1) {
          return true // iba ak je zena
        }
      }
      return false // default
    },
    doCancel() {
      this.$router.push('/dashboard')
    },
    saveTerminals() {
      const data_term = {}
      data_term.user_id = this.accountDataLocale.user_id
      data_term.hostname = this.accountDataLocale.hostname
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/user/update/${data_term.user_id}`,
        data: JSON.stringify(data_term),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'put',
      }
      axios(config)
        .then(resp => {
          console.log('saveTerminals ==> ', resp)
          const snackbar = {
            snack_text: 'Data uživatele byla uložena.',
            snack_color: 'primary',
          }
          this.$emit('snackbar', snackbar)
        })
        .catch(err => {
          const snackbar = {
            snack_text: this.responseError(err),
            snack_color: 'red',
          }
          this.$emit('snackbar', snackbar)
        })
    },
  },
}
</script>
