<template>
  <div>
    <v-card id="account-setting-card">
      <!-- tabs -->
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <account-settings-account
            :account-data="accountSettingData.account"
            @snackbar="snackBarMsg">
          </account-settings-account>
        </v-tab-item>

        <v-tab-item>
          <account-settings-email
            :information-data="accountSettingData.email"
            :info="info"
            @finish="refreshInfo"
            @snackbar="snackBarMsg">
          </account-settings-email>
        </v-tab-item>

        <v-tab-item>
          <account-settings-info
            :information-data="accountSettingData.telseznam"
            @snackbar="snackBarMsg">
          </account-settings-info>
        </v-tab-item>

        <v-tab-item>
          <account-settings-terminal :account-data="accountSettingData.account"></account-settings-terminal>
        </v-tab-item>

        <v-tab-item>
          <account-settings-security></account-settings-security>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- SNACKBAR -->
    <snack-bar
      :snackbar="snackbar"
      :color="snack_color"
      :text="snack_text"
      @close="snackbar=false"
    >
    </snack-bar>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: "off" */
/* eslint no-unneeded-ternary: "off" */
/* eslint prefer-object-spread: "off" */
/* eslint object-shorthand: "off" */

import axios from 'axios'
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiContactsOutline, mdiRemoteDesktop, mdiEmailEditOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import SnackBar from '@/components/snackbar/index.vue'
import response from '@/mixins/response'
import AccountSettingsAccount from './AccountSettingsAccount.vue'
import AccountSettingsSecurity from './AccountSettingsSecurity.vue'
import AccountSettingsInfo from './AccountSettingsInfo.vue'
import AccountSettingsTerminal from './AccountSettingsTerminal.vue'
import AccountSettingsEmail from './AccountSettingsEmail.vue'

export default {
  components: {
    'snack-bar': SnackBar,
    AccountSettingsAccount,
    AccountSettingsSecurity,
    AccountSettingsInfo,
    AccountSettingsTerminal,
    AccountSettingsEmail,
  },
  mixins: [response],
  data() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Účet', icon: mdiAccountOutline },
      { title: 'Email', icon: mdiEmailEditOutline },
      { title: 'Tel. seznam', icon: mdiContactsOutline },
      { title: 'Terminály', icon: mdiRemoteDesktop },
      { title: 'Bezpečnost', icon: mdiLockOpenOutline },
    ]

    // account settings data
    const accountSettingData = {
      account: {
        avatarImg: require('@/assets/images/avatars/1.png'),
        telseznam_id: undefined,
        login: 'administrator',
        email: 'admin@indexnoslus.cz',
        jmeno: 'Roland',
        prijmeni: 'Olejár',
        aktivni: 'Aktivní',
        personalNR: '99044',
      },
      telseznam: {
        telseznam_id: undefined,
        notes: '',
        birthday: '07 Únor, 1980',
        phone: '+420 775 587 009',
        phoneline: '',
        website: 'https://irool.cz/',
        gender: 'male',
      },
      email: {
        date_from: this.$moment(new Date()).format('YYYY-MM-DD'),
        date_to: this.$moment(new Date()).add(7, 'days').format('YYYY-MM-DD'),
        time_from: this.$moment(new Date()).add(1, 'hour').format('HH:00'),
        time_to: '17:00',
        forward_to: '',
        is_active: undefined,
      },
    }

    return {
      loading: true,
      fetch_settings: {
        filter: {
        },
        columns: {
        },
        paging: {
          limit: 999,
        },
        sort: {
          hostname: 1,
        },
      },
      tab,
      tabs,
      accountSettingData,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiContactsOutline,
        mdiRemoteDesktop,
        mdiEmailEditOutline,
      },
      snackbar: false,
      snack_color: 'red',
      snack_text: '',
      info: {},
    }
  },
  watch: {
  },
  created() {
    const user_id = this.$route.query.user_id || this.$store.getters.getProfile._id
    this.detailUser(user_id)
  },
  mounted() {
    this.loading = true
    const body = JSON.stringify(this.fetch_settings)
    const config = {
      url: `${process.env.VUE_APP_BASE_API}/api/v2/tool/terminals`,
      data: body,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'post',
    }
    axios(config)
      .then(resp => {
        this.accountSettingData.account.select_data = []
        for (let i = 0; i < resp.data.db_data.length; i += 1) {
          this.accountSettingData.account.select_data.push(resp.data.db_data[i].hostname)
        }
        this.loading = false
      })
      .catch(err => {
        this.loading = false
        console.error(err)
        this.snack_text = this.responseError(err)
        this.snack_color = 'red'
        this.snackbar = true
      })
  },
  methods: {
    isWoman(p_val) {
      /* eslint newline-before-return: "off" */

      if (p_val) {
        if (p_val.indexOf('ová') > -1) {
          return true // iba ak je zena
        }
      }
      return false // default
    },
    detailUser(p_id) {
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/user/${p_id}/profile`,
        data: {},
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          // console.log('profil =>', resp.data.db_data)

          // user

          this.accountSettingData.account.user_id = p_id
          this.accountSettingData.account.login = resp.data.db_data.login
          this.accountSettingData.account.email = resp.data.db_data.email
          this.accountSettingData.account.jmeno = resp.data.db_data.jmeno
          this.accountSettingData.account.prijmeni = resp.data.db_data.prijmeni
          this.accountSettingData.account.aktivni = resp.data.db_data.aktivni && resp.data.db_data.aktivni === true ? 'Aktivní' : 'Neaktivní'
          this.accountSettingData.account.personalNR = resp.data.db_data.personalNR
          const default_foto = this.isWoman(resp.data.db_data.prijmeni) ? require('@/assets/images/avatars/2.png') : require('@/assets/images/avatars/1.png')
          if (this.$store.getters.getProfile.profile && this.$store.getters.getProfile.profile.url_img) {
            this.accountSettingData.account.avatarImg = this.$store.getters.getProfile.profile.url_img
          } else {
            this.accountSettingData.account.avatarImg = resp.data.db_data.foto ? resp.data.db_data.foto : default_foto
          }
          this.accountSettingData.account.is_foto = resp.data.db_data.foto && resp.data.db_data.foto.length > 0 ? true : false
          this.accountSettingData.account.telseznam_id = resp.data.db_data.telseznam_id || undefined
          this.accountSettingData.account.hostname = []
          for (let i = 0; i < resp.data.db_data.terminals.length; i += 1) {
            this.accountSettingData.account.hostname.push(resp.data.db_data.terminals[i].hostname)
          }

          // telseznam

          this.accountSettingData.telseznam.telseznam_id = resp.data.db_data.telseznam_id || undefined
          this.accountSettingData.telseznam.notes = resp.data.db_data.notes
          this.accountSettingData.telseznam.birthday = resp.data.db_data.birthday
          this.accountSettingData.telseznam.phone = resp.data.db_data.phone
          this.accountSettingData.telseznam.gender = resp.data.db_data.gender
          this.accountSettingData.telseznam.website = resp.data.db_data.website ? resp.data.db_data.website : 'https://indexnoslus.cz'
          this.accountSettingData.telseznam.phoneline = resp.data.db_data.phoneline ? resp.data.db_data.phoneline : ''
          this.accountSettingData.telseznam.jobTitle = resp.data.db_data.jobTitle
          this.accountSettingData.telseznam.title = resp.data.db_data.title
          this.accountSettingData.telseznam.address = resp.data.db_data.address
          this.accountSettingData.telseznam.city = resp.data.db_data.city

          // email -> forward
          if (resp.data.db_data && resp.data.db_data.email_forward_to) {
            this.info.forward_to = (resp.data.db_data.email_forward_to.forward_to && resp.data.db_data.email_forward_to.forward_to.length > 0) ? resp.data.db_data.email_forward_to.forward_to.split('@')[0] : undefined
            this.info.date_from = (resp.data.db_data.email_forward_to.date_from && this.$moment(new Date(resp.data.db_data.email_forward_to.date_from)).format('YYYY-MM-DD')) || undefined
            this.info.date_to = (resp.data.db_data.email_forward_to.date_to && this.$moment(new Date(resp.data.db_data.email_forward_to.date_to)).format('YYYY-MM-DD')) || undefined
            this.info.time_from = (resp.data.db_data.email_forward_to.date_from && this.$moment(new Date(resp.data.db_data.email_forward_to.date_from)).format('HH:mm')) || undefined
            this.info.time_to = (resp.data.db_data.email_forward_to.date_to && this.$moment(new Date(resp.data.db_data.email_forward_to.date_to)).format('HH:mm')) || undefined
          }
        })
        .catch(err => {
          console.error(err)
          this.snack_text = this.responseError(err)
          this.snack_color = 'red'
          this.snackbar = true
        })
    },
    snackBarMsg(p_data) {
      this.snackbar = true
      this.snack_color = p_data.snack_color
      this.snack_text = p_data.snack_text
    },
    refreshInfo(p_data) {
      this.info = p_data
    },
  },
}
</script>
