import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c(VCardText,{staticClass:"d-flex"}),_c(VCardText,[_c(VForm,{staticClass:"multi-col-validation mt-6"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12"}},[_c(VSelect,{attrs:{"items":_vm.accountDataLocale.select_data,"menu-props":{ maxHeight: '400' },"label":"Select","multiple":"","hint":"Označte povolené terminály","persistent-hint":"","disabled":!_vm.$store.getters.getProfile.is_admin},model:{value:(_vm.accountDataLocale.hostname),callback:function ($$v) {_vm.$set(_vm.accountDataLocale, "hostname", $$v)},expression:"accountDataLocale.hostname"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"me-3 mt-4",attrs:{"color":"primary","disabled":!_vm.$store.getters.getProfile.is_admin},on:{"click":function($event){return _vm.saveTerminals()}}},[_vm._v(" Uložit změny ")]),_c(VBtn,{staticClass:"mt-4",attrs:{"color":"secondary","outlined":"","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.doCancel.apply(null, arguments)}}},[_vm._v(" Zrušit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }