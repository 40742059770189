<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-avatar
        rounded
        size="120"
        class="me-6"
      >
        <v-img :src="accountDataLocale.avatarImg"></v-img>
      </v-avatar>

      <!-- upload photo -->
      <div>
        <v-btn
          color="primary"
          class="me-3 mt-5"
          :disabled="!accountDataLocale.telseznam_id"
          @click="$refs.refInputEl.click()"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Nahrát novou fotku</span>
        </v-btn>

        <input
          ref="refInputEl"
          type="file"
          accept=".jpeg,.png,.jpg,GIF"
          :hidden="true"
          @change="uploadPhoto"
        />

        <v-btn
          color="error"
          outlined
          class="mt-5"
          :disabled="!this.accountDataLocale.telseznam_id"
          @click="removePhoto"
        >
          Odstranit foto
        </v-btn>
        <p class="text-sm mt-5">
          Povolené JPG, GIF nebo PNG. Maximální velikost 800K
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <!-- UZIVATELSKE MENO -->
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountDataLocale.login"
              label="Uživatelské jméno"
              dense
              outlined
              disabled
            ></v-text-field>
          </v-col>

          <!-- EMAIL -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountDataLocale.email"
              label="E-mail"
              dense
              outlined
              disabled
            ></v-text-field>
          </v-col>

          <!-- MENO -->
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountDataLocale.jmeno"
              label="Jméno"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <!-- PRIEZVISKO -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountDataLocale.prijmeni"
              dense
              label="Příjmení"
              outlined
            ></v-text-field>
          </v-col>

          <!-- AKTIVNI -->
          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="accountDataLocale.aktivni"
              dense
              outlined
              label="Stav"
              :items="status"
              :disabled="!$store.getters.getProfile.is_admin"
            ></v-select>
          </v-col>

          <!-- PERSONAL NR -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountDataLocale.personalNR"
              dense
              outlined
              label="Osobní číslo"
            ></v-text-field>
          </v-col>

          <!-- alert -->
          <!-- <v-col cols="12">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    Váš email není potvrzen. Zkontrolujte prosím svou doručenou poštu.
                  </p>
                  <a
                    href="javascript:void(0)"
                    class="text-decoration-none warning--text"
                  >
                    <span class="text-sm">Znovu odeslat potvrzení</span>
                  </a>
                </div>
              </div>
            </v-alert>
          </v-col> -->

          <!-- BUTTON, ulozit a zrusit -->
          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click="updateUser()"
            >
              Uložit změny
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="doCancel"
            >
              Zrušit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint no-unneeded-ternary: "off" */

import axios from 'axios'
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'

import { ref } from '@vue/composition-api'

import response from '@/mixins/response'

export default {
  mixins: [response],
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const status = ['Aktivní', 'Neaktivní']

    const accountDataLocale = ref(props.accountData) // ref(JSON.parse(JSON.stringify(props.accountData)))

    const resetForm = () => {
      accountDataLocale.value = JSON.parse(JSON.stringify(props.accountData))
    }
    return {
      status,
      accountDataLocale,
      resetForm,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
  watch: {
    'accountDataLocale.prijmeni': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.changePrijmeni(newVal)
        }
      },
    },
  },
  methods: {
    isWoman(p_val) {
      /* eslint newline-before-return: "off" */

      if (p_val) {
        if (p_val.indexOf('ová') > -1) {
          return true // iba ak je zena
        }
      }
      return false // default
    },
    changePrijmeni(p_val) {
      if (this.accountSettingData && this.accountSettingData.account && this.accountSettingData.account.is_foto) {
        const fotoMorW = this.isWoman(p_val) ? require('@/assets/images/avatars/2.png') : require('@/assets/images/avatars/1.png')
        this.accountDataLocale.avatarImg = fotoMorW
      }
    },
    doCancel() {
      this.$router.push('/dashboard')
    },
    updateUser(p_id) {
      p_id = p_id || this.$store.getters.getProfile._id
      if (this.$store.getters.getProfile.is_admin) {
        p_id = this.accountDataLocale.user_id
      }
      let data = _.cloneDeep(this.accountDataLocale)
      delete data.avatarImg
      delete data.is_foto
      delete data.user_id
      delete data.telseznam_id
      delete data.terminal_id
      delete data.email
      delete data.terminaly
      data.aktivni = data.aktivni === 'Aktivní' ? true : false
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/user/updateAccount/${p_id}`,
        data: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'put',
      }
      axios(config)
        .then(resp => {
          // console.log('profil =>', resp.data.db_data)
          let snackbar = {
            snack_text: 'Data uživatele byla uložena.',
            snack_color: 'primary',
          }
          this.$emit('snackbar', snackbar)
        })
        .catch(err => {
          console.error(err)
          let snackbar = {
            snack_text: this.responseError(err),
            snack_color: 'red',
          }
          this.$emit('snackbar', snackbar)
        })
    },
    uploadPhoto(e) {
      const files = e.target.files
      if (files[0] !== undefined) {
        let imageName = files[0].name
        if (imageName.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          let imageUrl = fr.result
          let imageFile = files[0] // this is an image file that can be sent to server...

          // console.log('*** uploadPhoto ***', imageName, imageUrl, imageFile)

          if (imageFile.size > 850000) { // ak je velkost suboru vacsia ako...
            let snackbar = {
              snack_text: 'Velkosť souboru je větší než 800Kb!',
              snack_color: 'red',
            }
            this.$emit('snackbar', snackbar)
          } else {
            this.accountDataLocale.avatarImg = imageUrl

            // UPLOAD request

            let data = {
              _id: this.accountDataLocale.telseznam_id,
              foto: imageUrl,
            }
            const config = {
              url: `${process.env.VUE_APP_BASE_API}/api/v2/telseznam/${this.accountDataLocale.telseznam_id}`,
              data: JSON.stringify(data),
              headers: {
                'Content-Type': 'application/json',
              },
              method: 'put',
            }
            axios(config)
              .then(resp => {
                let snackbar = {
                  snack_text: 'Data uživatele byla uložena.',
                  snack_color: 'primary',
                }
                this.$emit('snackbar', snackbar)
              })
              .catch(err => {
                console.error(err)
                let snackbar = {
                  snack_text: this.responseError(err),
                  snack_color: 'red',
                }
                this.$emit('snackbar', snackbar)
              })
          }
        })
      } else {
        this.imageName = ''
        this.imageFile = ''
        this.imageUrl = ''
      }
    },
    removePhoto(e) {
      const files = e.target.files
      this.accountDataLocale.avatarImg = undefined
      let data = {
        _id: this.accountDataLocale.telseznam_id,
        foto: null,
      }
      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/telseznam/${this.accountDataLocale.telseznam_id}`,
        data: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'put',
      }
      axios(config)
        .then(resp => {
          let snackbar = {
            snack_text: 'Fotka byla odstranena.',
            snack_color: 'primary',
          }
          this.$emit('snackbar', snackbar)
        })
        .catch(err => {
          console.error(err)
          let snackbar = {
            snack_text: this.responseError(err),
            snack_color: 'red',
          }
          this.$emit('snackbar', snackbar)
        })
    },
  },
}
</script>
